<template>
  <v-card class="mt-5">
    <v-toolbar
      dense
      flat
      dark
      color="secondary"
    >
      <v-spacer />
      <v-toolbar-title v-text="$t('multiproject.start.ccpm.title')" />
      <v-spacer />
    </v-toolbar>

    <v-card-text>
      <v-alert
        text
        prominent
        :color="userMessage.color"
        :icon="userMessage.icon"
      >
        <v-row no-gutters align="center" class="pl-5">
          <v-col cols="grow">
            <h3 class="headline" v-text="userMessage.title" />
            <span v-text="userMessage.subtitle" />
          </v-col>

          <v-col v-if="mustStartProject" cols="auto" class="mx-1">
            <v-btn
              :loading="loadingStartProject"
              :disabled="loadingStartProject"
              color="accent"
              @click="startProject"
              v-text="$t('multiproject.start.startProject')"
            />
          </v-col>
        </v-row>
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import AVAILABLE_STATUS from '../availableStatus'
import AVAILABLE_ACTIONS from '../availableActions'

export default {
  name: 'StartCcpmContainer',
  props: {
    game: {
      type: Object,
      required: true,
    },
    playerId: {
      type: String,
      required: true,
    },
  },
  inject: [
    'sendPlayerAction',
  ],
  data: () => ({
    loadingStartProject: false,
  }),
  computed: {
    currentPlayer () {
      return this.game.playerList.find(player => player.id === this.playerId)
    },
    currentStatus () {
      return this.currentPlayer.status
    },
    userMessage () {
      return {
        title: this.$t(`multiproject.start.ccpm.${this.currentStatus}.title`),
        subtitle: this.$t(`multiproject.start.ccpm.${this.currentStatus}.subtitle`),
        icon: this.$t(`multiproject.start.ccpm.${this.currentStatus}.icon`),
        color: this.$t(`multiproject.start.ccpm.${this.currentStatus}.color`),
      }
    },
    mustStartProject () {
      return this.currentStatus === AVAILABLE_STATUS.mustStartProject
    },
  },
  methods: {
    async startProject () {
      this.loadingStartProject = true
      await this.sendPlayerAction(AVAILABLE_ACTIONS.startProject)
      this.loadingStartProject = false
    },
  },
}
</script>
